import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, SnapshotAction } from '@angular/fire/compat/database';
import { Location, LocationNames, LocationNamesForUid, OrderDevicesParams } from '@airwallet/shared-models/location';
import { BookingSettings } from '@airwallet/shared-models/booking-settings';
import { Basic } from '@airwallet/shared-models/device';
import { PayoutSettings } from '@airwallet/shared-models/payouts';
import { Subscription } from 'rxjs';
import * as Terminal from '@airwallet/shared-models/terminal';
import * as Device from '@airwallet/shared-models/device';
import { HttpService, RequestTypes } from '../helper/http.service';
import { Address } from '@airwallet/shared-models/stripe';
import { LocationResponse } from '@airwallet/shared-models/search-params/locationSpecific';
import { Hub } from '@airwallet/shared-models/hub/hub';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private deviceList: Basic[];

    constructor(
        private db: AngularFireDatabase,
        private httpService: HttpService
    ) {}

    readDefaultBookingSettings(): AngularFireObject<BookingSettings> {
        return this.db.object(`default_booking_settings`);
    }

    readLocation(uid: string, locId: string): AngularFireObject<Location> {
        return this.db.object(`customers/${uid}/locations/${locId}`);
    }

    readLocationNames(uid: string): AngularFireObject<Record<string, string>> {
        return this.db.object(`search_lists/${uid}/locations/name`);
    }

    async getLocationNames(uid: string): Promise<{ value: string; label: string }[]> {
        console.log('Getting location names', uid);

        return this.httpService.dynamicHttp(`api_location/names_for_filter`, RequestTypes.GET, { onBehalf: uid });
    }

    async getAllLocationNames(uid: string): Promise<LocationNames[]> {
        return this.httpService.dynamicHttp(`api_location/names`, RequestTypes.GET, { onBehalf: uid });
    }
    async getAllLocationNamesMappedToUid(uid: string, mapToUid?: boolean): Promise<LocationNamesForUid[]> {
        return this.httpService.dynamicHttp(`api_location/names`, RequestTypes.GET, { onBehalf: uid, params: { mapToUid } });
    }

    async getAllLocationNamesRecord(uid: string): Promise<Record<string, string>> {
        return this.httpService.dynamicHttp(`api_location/names`, RequestTypes.GET, { onBehalf: uid });
    }

    validateAddress(address: Address): Promise<any> {
        return this.httpService.dynamicHttp(`api_location/validate`, RequestTypes.POST, { body: { address } });
    }

    checkIfCustomIdExistsAnyLocation(uid: string, val: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const subscription = this.db
                .list(`customers/${uid}/locations`, ref => ref.orderByChild('location_custom_id').equalTo(val))
                .valueChanges()
                .subscribe(
                    (locations: any[]) => {
                        subscription.unsubscribe(); // Unsubscribe before resolving
                        resolve(locations); // Resolve with the locations where location_custom_id equals to val
                    },
                    error => {
                        subscription.unsubscribe(); // Unsubscribe before rejecting
                        reject(error); // Reject with the error if there's any issue
                    }
                );
        });
    }

    getDevices() {
        return this.deviceList;
    }

    setDevices(array: Basic[]) {
        this.deviceList = Object.assign([], array);
    }

    async readLocationsAsPromise(uid: string): Promise<Record<string, Location>> {
        return new Promise((resolve, reject) => {
            const location_sub: Subscription = this.db
                .object(`customers/${uid}/locations`)
                .snapshotChanges()
                .subscribe((locations_snap: SnapshotAction<Record<string, Location>>) => {
                    location_sub.unsubscribe();
                    const locations: Record<string, Location> = locations_snap.payload.val();
                    return resolve(locations);
                });
        });
    }

    async readPayoutSettings(uid: string): Promise<PayoutSettings> {
        return new Promise(async (resolve, reject) => {
            const payoutSettingsSub: Subscription = this.db
                .object(`customers/${uid}/payout_settings`)
                .snapshotChanges()
                .subscribe((payoutSettingsSnap: SnapshotAction<PayoutSettings>) => {
                    payoutSettingsSub.unsubscribe();
                    return resolve(payoutSettingsSnap.payload.val());
                });
        });
    }

    async sortDevices(params: OrderDevicesParams, onBehalf: string): Promise<{ terminals: Record<string, Terminal.Unit>; devices: Record<string, Device.Unit> }> {
        return this.httpService.dynamicHttp(`api_device/sort_devices`, RequestTypes.PUT, { body: params, onBehalf });
    }

    async resetSortDevices(locationId: string, onBehalf: string): Promise<{ terminals: Record<string, Terminal.Unit>; devices: Record<string, Device.Unit> }> {
        return this.httpService.dynamicHttp(`api_device/reset_sorted_devices`, RequestTypes.PUT, { body: { locationId }, onBehalf });
    }

    async getLocations(uid: string, params: string): Promise<LocationResponse> {
        return this.httpService.dynamicHttp(`api_location`, RequestTypes.GET, { params: { params }, onBehalf: uid });
    }

    async createLocation(location: Location, uid: string): Promise<any> {
        return this.httpService.dynamicHttp(`api_location`, RequestTypes.POST, { body: { location }, onBehalf: uid });
    }

    async updateLocation(uid: string, location: Location): Promise<any> {
        return this.httpService.dynamicHttp(`api_location`, RequestTypes.PUT, { body: location, onBehalf: uid });
    }

    async deleteLocation(uid: string, locationId: string): Promise<any> {
        return this.httpService.dynamicHttp(`api_location/${locationId}`, RequestTypes.DELETE, { onBehalf: uid });
    }

    async updateBookingSettings(req: { uid: string; locationId: string; settings: BookingSettings }): Promise<any> {
        return this.httpService.dynamicHttp(`api_location/update_booking_settings`, RequestTypes.PUT, { body: req });
    }

    async getDeviceStats(uid: string, location_id: string): Promise<{ terminals: Record<string, Terminal.Unit>; devices: Record<string, Device.Unit>; hubs: Hub[] }> {
        return this.httpService.dynamicHttp(`api_device/stats`, RequestTypes.GET, { params: { location: location_id }, onBehalf: uid });
    }
}
