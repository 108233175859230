import { Injectable } from '@angular/core';
import { ExternalAccount, ExternalAccountCreateParams, ExternalAccountUpdateParams } from '@airwallet/shared-models/external_accounts';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExternalAccountService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private db: AngularFireDatabase
    ) {}

    readPayoutSettings(uid: string): AngularFireObject<boolean> {
        return this.db.object(`customers/${uid}/payout_settings/auto`);
    }

    async createExternalAccount(externalAccountCreateParams: ExternalAccountCreateParams): Promise<ExternalAccount> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_external_accounts`, externalAccountCreateParams))
                .then((res: ExternalAccount) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getExternalAccount(key: string): Promise<ExternalAccount> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_external_accounts?key=${key}`))
                .then((res: ExternalAccount) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getAllExternalAccounts(uid?: string): Promise<Record<string, ExternalAccount>> {
        return new Promise(async (resolve: any, reject: any) => {
            const externalAccounts: Record<string, ExternalAccount> = await lastValueFrom(this.http.get(`${environment.baseUrl}/api_external_accounts/all`, await this.authService.addHeaders(uid)))
                .then((res: Record<string, ExternalAccount>) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
            return externalAccounts;
        });
    }

    async getCurrenciesForCountry(country: string): Promise<{ default_currency: string; currencies: Record<string, string[]> }> {
        return new Promise(async (resolve: any, reject: any) => {
            const currencyData: { default_currency: string; currencies: Record<string, string[]> } = await lastValueFrom(this.http.get(`${environment.baseUrl}/api_external_accounts/currencies?country=${country}`))
                .then((res: { default_currency: string; currencies: Record<string, string[]> }) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
            return currencyData;
        });
    }

    async updateExternalAccount(externalAccountUpdateParams: ExternalAccountUpdateParams): Promise<ExternalAccount> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${environment.baseUrl}/api_external_accounts`, externalAccountUpdateParams))
                .then((res: ExternalAccount) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async deleteExternalAccount(key: string): Promise<ExternalAccount> {
        return new Promise(async (resolve: any, reject: any) => {
            const options = {
                body: { key }
            };
            return lastValueFrom(this.http.delete(`${environment.baseUrl}/api_external_accounts`, options))
                .then((res: ExternalAccount) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
