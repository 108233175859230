<div>
    <div style="position: relative" (mouseenter)="enableTooltip()" (mouseleave)="disableTooltip()" #elementContainer>
        @if (showTooltip) {
            <div #tooltip class="custom-tool-tip" style="min-width: 100%" [style.transform]="transform">
                <div class="tool-tip-content">
                    <div class="mb-2 d-flex tool-tip-container">
                        <span class="img-span"><img src="../../../assets/icons/modal-info-icon.svg" alt="{{ 'misc.info' | translate }}" /></span>
                        <span class="tooltip-title">{{ tooltipTitle() }}</span>
                        <ng-content select="[title]"> </ng-content>
                    </div>
                    <span class="description" style="display: inline-block">{{ description() }}</span>
                    <div class="description">
                        <ng-content select="[description]"> </ng-content>
                    </div>
                </div>
            </div>
        }
        <ng-content #element select="[tooltip_element_ref]"> </ng-content>
    </div>
</div>
