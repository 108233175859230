import { Component, ElementRef, input, InputSignal, OnInit, output, OutputEmitterRef, Signal, viewChild } from '@angular/core';
import { Calendar, DateSelectArg, EventChangeArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { NgIf } from '@angular/common';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from '@services/helper/helper.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from '@components/loading/loading.component';
import { Week, WeekWithId } from '@airwallet/shared-models/location';
import dayjs from 'dayjs';
export enum Weekdays {
    SUN = 'sun', // is 0
    MON = 'mon',
    TUE = 'tue',
    WED = 'wed',
    THU = 'thu',
    FRI = 'fri',
    SAT = 'sat'
}

export interface Event {
    start: string;
    end: string;
}

/**
 * @description This component is a calendar grid that allows the user to select and drag events to create a schedule.
 *
 * @param calendarClosed - Event emitter that emits the week object when the calendar is closed, not required, but recommended
 * @param slotIncrements - Input signal that sets the slot increments of the calendar, not required.
 * @requires modalTitle
 *
 * @example
 * <app-aw-calendar-grid (calendarClosed)=anyFunction($event) [slotIncrements]></app-aw-calendar-grid>
 *
 * anyFunction(event: Week) {
 *      console.log(week)
 * }
 * @emits Week - Emits the week object when the calendar is closed
 * @see aw-calendar-layout - for more information on how to show the results on the page
 */
@Component({
    selector: 'app-aw-calendar-grid',
    standalone: true,
    imports: [CustomModalComponent, TranslateModule, LoadingComponent, NgIf],
    templateUrl: './aw-calendar-grid.component.html',
    styleUrl: './aw-calendar-grid.component.scss'
})
export class AwCalendarGridComponent implements OnInit {
    calendarModal: Signal<ElementRef> = viewChild.required('calendarModal');
    calendarClosed: OutputEmitterRef<Week> = output();
    slotIncrements: InputSignal<string | undefined> = input<string | undefined>();
    savedWeek: InputSignal<Week | undefined> = input<Week | undefined>();
    /**
     * @param modalTitle - Input signal that sets the title of the modal.
     * @description required - The title is required to be set. Mainly use translated text.
     */
    modalTitle: InputSignal<string> = input.required<string>();
    calendar: Calendar;
    week: Week = {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {}
    };
    selectedEventId: string; // only used for when mobile view is interacted with
    processedEvents: { day: string; events: unknown[] }[];
    dashboardUser: DashboardUser;
    isMobile = false;
    isLoading = true;
    modalRef: NgbModalRef;
    weekWithIds: WeekWithId;
    handleTouchMoveVar = this.handleTouchMove.bind(this);
    init = true;
    wasDragging = false;
    constructor(
        private helperService: HelperService,
        private bpo: BreakpointObserver,
        public translate: TranslateService,
        private modalService: NgbModal
    ) {
        this.bpo.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnInit(): void {
        this.dashboardUser = this.helperService.getUser();
        this.modalRef = this.modalService.open(this.calendarModal(), {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
            animation: false,
            keyboard: false,
            fullscreen: this.isMobile
        });

        this.modalRef.result
            .then(() => {
                this.saveSelections();
                console.log('Modal closed');
            })
            .catch(err => {
                console.log(err);
                this.saveSelections();
                console.log('Modal dismissed');
            });

        if (this.savedWeek()) {
            // Deep copy using JSON serialization and deserialization

            // Convert 2359 (saved like that for the app) to 2400, so the calendar can handle it
            // And then all the places we save location data we convert from 2400 (what the calendar outputs) to 2359
            Object.keys(this.savedWeek()).forEach(day => {
                Object.keys(this.savedWeek()[day]).forEach(eventKey => {
                    console.log(this.savedWeek()[day][eventKey].end);
                    if (this.savedWeek()[day][eventKey].end === '2359') {
                        this.savedWeek()[day][eventKey].end = '2400';
                    }
                });
            });

            this.week = JSON.parse(JSON.stringify(this.savedWeek()));
        }
        this.renderCalendar();
        this.isLoading = false;
    }

    renderCalendar() {
        const calendarEl: HTMLElement = document.getElementById('calendar');
        if (!calendarEl) return;
        this.calendar = new Calendar(calendarEl, {
            plugins: [interactionPlugin, timeGridPlugin],
            initialView: this.isMobile ? 'timeGridDay' : 'timeGridWeek',
            dayHeaderFormat: { weekday: 'short' },
            headerToolbar: {
                start: '',
                center: this.isMobile ? 'myCustomLeftButton myCustomRightButton' : '',
                end: ''
            },
            customButtons: {
                myCustomLeftButton: {
                    icon: 'chevron-left',
                    click: this.mobileNavigator.bind(this)
                },
                myCustomRightButton: {
                    icon: 'chevron-right',
                    click: this.mobileNavigator.bind(this)
                }
            },
            editable: true,
            eventResizableFromStart: true,
            eventStartEditable: true,
            eventOverlap: false,
            longPressDelay: 500,
            selectable: true,
            displayEventEnd: true,
            selectOverlap: false,
            selectMirror: true,
            allDaySlot: false,
            handleWindowResize: true,
            eventDragMinDistance: 5,
            slotDuration: this.slotIncrements() ? this.slotIncrements() : '00:15:00',
            slotLabelInterval: '01:00', // Possible to make as input
            slotMinTime: '00:00:00', // Possible to make as input
            slotMaxTime: '24:00:00', // Possible to make as input
            scrollTime: '06:00:00', // Possible to make as input
            locale: this.dashboardUser?.settings?.country === 'US' ? 'en-US' : 'en-GB',
            firstDay: this.dashboardUser?.settings?.country === 'US' ? 0 : 1,
            slotLabelFormat:
                this.dashboardUser?.settings?.country === 'US'
                    ? {
                          // am pm time
                          hour: 'numeric',
                          minute: '2-digit',
                          omitZeroMinute: true,
                          meridiem: 'short'
                      }
                    : {
                          // military time
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                      },
            eventTimeFormat:
                this.dashboardUser?.settings?.country === 'US'
                    ? {
                          // am pm time
                          hour: 'numeric',
                          minute: '2-digit',
                          omitZeroMinute: true,
                          meridiem: 'short'
                      }
                    : {
                          // military time
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                      },
            dayHeaderContent: arg => {
                return `${this.translate.instant(`misc.weekdays.${arg.date.toString().split(' ')[0].toLowerCase()}.short`)}.`;
            },
            selectAllow: arg => {
                // blocks if user tries to select over multiple days
                const start: string = arg.startStr;
                const end: string = arg.endStr;
                return this.validateSelectionAndDrag(start, end);
            },
            //Dropinfo and draggedEvent
            eventAllow: dropInfo => {
                // blocks if user tries to drag a block to cross between two days
                const start: string = dropInfo.startStr;
                const end: string = dropInfo.endStr;
                return this.validateSelectionAndDrag(start, end);
            },
            select: this.handleDateSelect.bind(this), // when calendar is hold and cursor dragged (selection is made)
            eventClick: this.handleEventClick.bind(this), // if an event is clicked
            dateClick: this.handleDateClick.bind(this), // only used for mobile
            eventChange: this.handleEventChange.bind(this), // updates events
            events: this.loadEvents(),
            eventContent: this.handleEventContent.bind(this),
            viewDidMount: () => {
                if (this.init) {
                    this.init = false;
                }
            },
            eventDragStop: () => {
                this.wasDragging = true;
            }
        });

        this.calendar.render();
    }

    handleEventContent(arg: EventContentArg, el) {
        if (!this.init) {
            document.addEventListener('touchmove', this.handleTouchMoveVar, { passive: false });

            if (this.wasDragging) {
                this.wasDragging = false;
                document.removeEventListener('touchmove', this.handleTouchMoveVar);
            }
        }
        const endStr = dayjs(arg.event.end).format('HH:mm');
        const startStr = dayjs(arg.event.start).format('HH:mm');

        if (endStr === '00:00' && startStr === '00:00') {
            const newTitle = `${startStr} - ${dayjs(arg.event.end).subtract(1, 'minute').format('HH:mm')}`;
            return el('div', { className: 'fc-content', style: 'display:flex; justify-content:center' }, el('div', { className: 'fc-title', style: 'font-size: 10px;' }, newTitle));
        } else {
            const newTitle = `${startStr} - ${endStr}`;
            return el('div', { className: 'fc-content', style: 'display:flex; justify-content:center' }, el('div', { className: 'fc-title', style: 'font-size: 10px;' }, newTitle));
        }
    }

    removeDeleteButtonFromEvents() {
        const eventElement = document.querySelector('.aw-fc-close-icon');
        if (eventElement) eventElement.remove();
    }

    handleEventClick(arg: EventClickArg) {
        if (this.isMobile) {
            this.selectedEventId = arg.event.id;
        } else {
            this.removeDeleteButtonFromEvents(); // remove previous close icon before adding a new
            this.addDeleteButtonToEvent(arg); // add close icon to event
        }
    }

    handleDateClick(arg: DateClickArg) {
        if (this.isMobile) {
            this.selectedEventId = null;
        }
    }

    handleEventChange(arg: EventChangeArg) {
        // Function updates the event
        console.log('Event changed:', arg);
        const { event, oldEvent } = arg;
        const dateFormat = moment(event.startStr).creationData().format as string;

        if (moment(oldEvent.startStr).weekday() !== moment(event.startStr).weekday()) {
            // day have changed so old entry should be removed
            delete this.week[this.getWeekdayFromDateStr(oldEvent.startStr, dateFormat)][event.id];
        }

        if (this.week[this.getWeekdayFromDateStr(event.startStr, dateFormat)]) {
            this.week[this.getWeekdayFromDateStr(event.startStr, dateFormat)][event.id] = {
                start: `${moment(event.startStr, dateFormat).format('HHmm')}`,
                end: `${moment(event.endStr, dateFormat).format('HHmm') === '0000' ? '2400' : moment(event.endStr, dateFormat).format('HHmm')}`
            };
        } else {
            this.week[this.getWeekdayFromDateStr(event.startStr, dateFormat)] = {
                [event.id]: {
                    start: `${moment(event.startStr, dateFormat).format('HHmm')}`,
                    end: `${moment(event.endStr, dateFormat).format('HHmm') === '0000' ? '2400' : moment(event.endStr, dateFormat).format('HHmm')}`
                }
            };
        }
        console.log(this.week[this.getWeekdayFromDateStr(event.startStr, dateFormat)][event.id]);
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    saveSelections() {
        this.processEvents();

        Object.keys(this.week).forEach(day => {
            Object.keys(this.week[day]).forEach(eventKey => {
                const event: Event = this.week[day][eventKey];

                if (event.end === '2400') {
                    event.end = '2359';
                }
            });
        });

        this.calendarClosed.emit(this.week);
        this.modalRef.close();
    }

    processEvents() {
        this.processedEvents = [];
        Object.keys(this.week)
            // Sorts the days, by the const days object, which is the order of the days in a week.
            .sort((a, b) => {
                const days = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };
                return days[a] - days[b];
            })
            .forEach(day => {
                const events = Object.keys(this.week[day]).map(eventKey => {
                    const startMilTime = this.week[day][eventKey].start;
                    const endMilTime = this.week[day][eventKey].end;

                    //Formatting military time to HH:MM
                    const formattedStart = this.formatTime(startMilTime);
                    const formattedEnd = this.formatTime(endMilTime);

                    return {
                        start: formattedStart,
                        end: formattedEnd
                    };
                });

                // Sorts the events based by the time, for each day.
                events.sort((a, b) => {
                    return parseInt(a.start.replace(':', '')) - parseInt(b.start.replace(':', ''));
                });

                if (events.length > 0) {
                    this.processedEvents.push({ day, events });
                }
            });
    }

    formatTime(militaryTime: string): string {
        const hours = militaryTime.substring(0, 2);
        const minutes = militaryTime.substring(2);
        return `${hours}:${minutes}`;
    }

    addDeleteButtonToEvent(arg: EventClickArg) {
        const el: Element = arg.el.children.item(0).children.item(0).children.item(0); // element that holds the event label (div)
        const closeBtn = document.createElement('button');
        closeBtn.className = 'aw-fc-close-icon';

        closeBtn.addEventListener('click', () => {
            this.removeEvent(arg.event.id);
        });

        el.appendChild(closeBtn); // inserts the button into the HTML
    }

    removeEvent(eventId?: string) {
        const event = this.calendar.getEventById(eventId ? eventId : this.selectedEventId);
        event.remove(); // removes the event
        delete this.week[this.getWeekdayFromDateStr(event.startStr, moment(event.startStr).creationData().format as string)][event.id];
        if (!eventId) this.selectedEventId = null;
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    handleTouchMove(e: TouchEvent) {
        e.preventDefault();
    }

    handleDateSelect(arg: DateSelectArg) {
        console.log(arg);
        this.calendar.unselect(); // clears the selects before it adds it as a event
        const id: string = this.helperService.createPushKey();
        const dateFormat = moment(arg.startStr).creationData().format as string;
        const start: string = arg.startStr;
        const end: string = arg.endStr;
        const addEventParams = { start, end, id };
        this.calendar.addEvent(addEventParams);

        const weekday: Weekdays = this.getWeekdayFromDateStr(start, dateFormat);
        if (this.week[weekday]) {
            this.week[weekday][id] = {
                start: `${moment(start, dateFormat).format('HHmm')}`,
                end: `${moment(end, dateFormat).format('HHmm') === '0000' ? '2359' : moment(end, dateFormat).format('HHmm')}`
            };
        } else {
            this.week[weekday] = {
                [id]: {
                    start: `${moment(start, dateFormat).format('HHmm')}`,
                    end: `${moment(end, dateFormat).format('HHmm') === '0000' ? '2359' : moment(end, dateFormat).format('HHmm')}`
                }
            };
        }

        console.log(this.week[weekday][id]);
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    mobileNavigator(arg: PointerEvent) {
        const isUS: boolean = this.dashboardUser?.settings?.country === 'US' ? true : false;
        if (arg.target['className'].includes('right')) {
            // going forwards
            if (moment(this.calendar.getDate()).weekday() !== (isUS ? 6 : 0))
                // allowed to go forwards if weekday is 5 or lower in US, else 6 or lower in other countries (saturday is 6)
                this.calendar.next();
        } else {
            // going backwards
            if (moment(this.calendar.getDate()).weekday() !== (isUS ? 0 : 1))
                // allowed to go backwards if weekday is 0 or higher in US, else 1 or higher in other countries (sunday is 0)
                this.calendar.prev();
        }
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    validateSelectionAndDrag(start: string, end: string): boolean {
        // end of day is tagged as begining of next day, validation here check for that
        return moment(start).weekday() === moment(end).weekday() || // if same day, all "OK"
            (moment(end).format('HHmm') === '0000' && // OR if end is value '0000' (we know it is the last event)
                (moment(start).weekday() === moment(end).weekday() - 1 || // AND if endStr is one day ahead, all "OK"
                    (moment(start).weekday() === 6 && moment(end).weekday() === 0))) // OR if sunday it should be start on saturday, all "OK"
            ? true
            : false;
    }

    loadEvents(): Event[] {
        console.log('week:', this.week);
        const payload = [];
        for (const weekday in this.week) {
            for (const eventId in this.week[weekday]) {
                const event: Event = this.week[weekday][eventId];
                const sundayFix: number = // sundayFix is to ensure that the sunday is the upcoming Sunday
                    weekday === 'sun' && // must be a sunday
                    moment(weekday, 'ddd').endOf('day').isBefore() // and if end of day is before now, then it is the previous sunday and not the upcoming
                        ? 1
                        : 0; // if statement is true, then 1 to add a week, else 0 to not add

                payload.push({
                    start: moment(`${moment(weekday, 'ddd').add(sundayFix, 'week').format('YYYY-MM-DD')} ${event.start}`, 'YYYY-MM-DD HHmm').toISOString(),
                    end: moment(`${moment(weekday, 'ddd').add(sundayFix, 'week').format('YYYY-MM-DD')} ${event.end}`, 'YYYY-MM-DD HHmm').toISOString(),
                    id: eventId,
                    title: `${moment(event.start, 'HHmm').format('HH:mm')} - ${moment(event.end, 'HHmm').format('HH:mm')}`
                });
            }
        }
        console.log('payload:', payload);
        return payload;
    }

    getWeekdayFromDateStr(dateStr: string, dateFormat: string): Weekdays {
        // find the weekday in three-char format from our enum
        return Weekdays[Object.keys(Weekdays)[moment(dateStr, dateFormat).weekday()]];
    }

    closeModal() {
        if (this.savedWeek()) {
            this.calendarClosed.emit(this.savedWeek());
        } else {
            this.calendarClosed.emit({
                mon: {},
                tue: {},
                wed: {},
                thu: {},
                fri: {},
                sat: {},
                sun: {}
            });
        }

        this.modalRef.close();
    }

    cleanAll() {
        const date: Date = this.calendar.view.calendar.getDate();

        if (this.isMobile) {
            const events = this.calendar.getEvents();
            const day = this.getWeekdayFromDateStr(date.toString(), 'ddd');
            events.forEach(event => {
                const eventDay = this.getWeekdayFromDateStr(event.startStr, moment(event.startStr).creationData().format as string);
                if (this.week[day] && day === eventDay) {
                    delete this.week[day][event.id];
                    event.remove();
                }
            });
        } else {
            this.week = {};
            this.calendar.removeAllEvents();
        }
    }
}
