<div [className]="htmlClass" [style.gap]="gap + 'rem'">
    <ng-content select="[title]"> </ng-content>

    <div [ngClass]="labelClass" *ngIf="label">{{ label }}</div>
    <h4 *ngIf="header" class="tool-tip-title" [ngClass]="titleClass">{{ header }}</h4>

    <app-aw-tooltip [tooltipTitle]="label ? label : header ? header : tooltipTitle ? tooltipTitle : null" [description]="description">
        <ng-content title select="[tooltip-title]"> </ng-content>
        <ng-content description select="[description]"></ng-content>
        <img
            tooltip_element_ref
            [className]="iconClass === undefined ? 'icon' : 'icon ' + iconClass"
            (mouseenter)="showInfo = true"
            (mouseleave)="showInfo = false"
            src="../../../assets/icons/info-circle.svg"
            alt="{{ 'misc.info' | translate }}"
        />
    </app-aw-tooltip>
</div>
