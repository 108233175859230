import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, input, viewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export type Position = 'left' | 'right' | 'top' | 'bottom' | 'top_left' | 'bottom_left' | 'top_right' | 'bottom_right';
/**
 *
 * @description This is a custom component that provides tooltips, where you include the title and description.
 * Positions itself to be the most visible on the screen.
 *
 * Properties available:
 * @input `tooltipTitle` - The title for the tooltip.
 * @input `description` - The description text for the tooltip.
 * @input `selectPosition` - The position of the tooltip (top | bottom | left | right) as a fallback.
 *
 * @example
 * ```html
 * <app-aw-tooltip tooltipTitle="Title" description="Description" selectPosition="top">
 *  <div element>Content</div>
 * </app-aw-tooltip>
 *
 * ```
 *
 */
@Component({
    selector: 'app-aw-tooltip',
    standalone: true,
    imports: [TranslateModule, NgStyle],
    templateUrl: './aw-tooltip.component.html',
    styleUrl: './aw-tooltip.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwTooltipComponent {
    tooltipTitle = input.required<string>();
    description = input<string>();
    selectPosition = input<Position>('top');
    showTooltip = false;
    elementRef = viewChild.required<ElementRef>('elementContainer');
    transform = '';
    isMobile = false;
    constructor(
        private cdr: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }
    /**
     * @description Opens the tooltip
     * @returns void
     */
    enableTooltip() {
        if (this.isMobile) {
            return;
        }

        const element: HTMLElement = this.elementRef().nativeElement.children[0] as HTMLElement;
        const rect = element.getBoundingClientRect();
        const padding = 10;

        const topHeight = this.elementRef().nativeElement.clientHeight;
        const bottomHeight = this.elementRef().nativeElement.clientHeight + padding;
        const leftWidth = this.elementRef().nativeElement.clientWidth;
        const rightWidth = this.elementRef().nativeElement.clientWidth + padding;

        switch (this.findPosition(rect)) {
            case 'left':
                this.transform = `translateX(-100%) translateX(-${leftWidth}px)`;
                break;
            case 'right':
                this.transform = `translateX(${rightWidth}px)`;
                break;
            case 'top':
                this.transform = `translateY(-100%) translateY(-${padding}px)`;
                break;
            case 'bottom':
                this.transform = `translateY(${bottomHeight}px)`;
                break;
            case 'top_left':
                this.transform = `translateY(-100%) translateY(-${padding}px) translateX(-100%) translateX(${leftWidth}px)`;
                break;
            case 'bottom_left':
                this.transform = `translateY(${bottomHeight}px) translateX(-100%) translateX(${leftWidth}px)`;
                break;
            case 'top_right':
                this.transform = `translateY(-100%) translateY(-${padding}px)`;
                break;
            case 'bottom_right':
                this.transform = `translateY(${bottomHeight}px)`;
                break;
            default:
                this.transform = `translateY(-100%) translateY(-${topHeight}px)`;
        }
        this.showTooltip = true;
        this.cdr.detectChanges();
    }

    disableTooltip() {
        if (this.isMobile) {
            return;
        }

        this.showTooltip = false;
        this.cdr.detectChanges();
    }

    /**
     *
     * @param element ElementRef
     * @param rect DOMRect
     * @returns Position (left | right | top | bottom)
     *
     * @description Decides the position of the tooltip based on the element and the window size
     */
    findPosition(rect: DOMRect): Position {
        const { left, top, right, bottom } = rect;
        const { innerWidth, innerHeight } = window;

        // These are all the spaces available for the tooltip
        const spaceLeft = left;
        const spaceRight = innerWidth - right;
        const spaceTop = top;
        const spaceBottom = innerHeight - bottom;

        // These are the spaces available for the tooltip in the corners
        const spaceTopRight = Math.max(spaceTop, spaceRight);
        const spaceTopLeft = Math.max(spaceTop, spaceLeft);
        const spaceBottomRight = Math.max(spaceBottom, spaceRight);
        const spaceBottomLeft = Math.max(spaceBottom, spaceLeft);

        const values = [
            { position: 'top_right', space: spaceTopRight },
            { position: 'top_left', space: spaceTopLeft },
            { position: 'bottom_right', space: spaceBottomRight },
            { position: 'bottom_left', space: spaceBottomLeft },
            { position: 'top', space: spaceTop },
            { position: 'bottom', space: spaceBottom },
            { position: 'left', space: spaceLeft },
            { position: 'right', space: spaceRight }
        ];

        const maxSpace = values.reduce((prev, current) => (prev.space > current.space ? prev : current));

        if (spaceTop / 1.2 > spaceBottom && maxSpace.position === 'right') {
            return 'top_right';
        }

        if (spaceBottom / 1.2 > spaceTop && maxSpace.position === 'right') {
            return 'bottom_right';
        }

        // All the below statements, check if there is enough space for the tooltip to be placed top bottom left or right
        if (spaceTop > innerHeight && maxSpace.position === 'left') {
            return 'top_left';
        }

        if (spaceBottom > innerHeight && maxSpace.position === 'left') {
            return 'bottom_left';
        }

        if (spaceTop > innerHeight && maxSpace.position === 'right') {
            return 'top_right';
        }

        if (spaceBottom > innerHeight && maxSpace.position === 'right') {
            return 'bottom_right';
        }

        // Fixed size for when position is left, it should go right instead if there is space
        // This is because all text normally is on the left of a tooltip, meaning we prefer position.right

        if (maxSpace.position === 'right') {
            return 'right';
        }

        if (maxSpace.position === 'left' && spaceRight > 325) {
            return 'right';
        }

        if (maxSpace.position === 'top' && spaceRight > 325) {
            return 'top_right';
        }

        if (maxSpace.position === 'bottom' && spaceTopRight > 325) {
            return 'bottom_right';
        }

        return maxSpace.position as Position;
    }
}
